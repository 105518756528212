{{#if anchor}}<a id="{{anchor}}"></a>{{/if}}
<div class="section-line" data-visible="{{visible}}">
	{{#if verses}}
		<Paragraphs
			:verses
			:descriptionClass
			:siblingAnchor
			:siblingIsDown
			:description
			:range
			:sermons
			:contentClass
			:showVerseNumber
		/>
	{{else}}
		<div class="section-horizontal-units">
			<div class="section-content {{contentClass}}">
				<ParagraphDescription
					:descriptionClass
					:siblingAnchor
					:siblingIsDown
					:description
					:range
					:sermons
				/>
				<div class="section-text content-section-margin">
					{{yield}}
				</div>
			</div>
		</div>
	{{/if}}

	{{#if sectionIdentifier}}
		<Link
			className="color-bar septet-color-bar unselectable"
			style="background-color: {{getChiasmColor(sectionIdentifier)}}"
			parameters="{{ linkParameters }}"
		>
			{{shortSeptetLabel(sectionIdentifier)}}
		</Link>
	{{else}}
		<div class="color-bar septet-color-bar"></div>
	{{/if}}
</div>

<style>
.sibling-link {
	text-decoration: none;
}

[data-visible=false] {
	display: none;
}

[data-sibling-hovered=true] {
	color: #ba4460;
}

</style>

<script>
import ParagraphDescription from './ParagraphDescription.html'
import Paragraphs from './Paragraphs.html'

import getChiasmColor from 'lib/structure/identifier-color'
import svelteQuerystringRouter from 'svelte-querystring-router'
const { Link } = svelteQuerystringRouter
import septetLabel from 'lib/structure/septet-label'

const removeProperties = (object, properties) => {
	const clone = Object.assign({}, object)

	properties.forEach(property => {
		delete clone[property]
	})

	return clone
}

export default {
	data() {
		return {
			descriptionClass: '',
			contentClass: '',
			chiasmIdentifier: null,
			sectionIdentifier: null,
			zoomedIn: false,
			visible: true,
			sermons: []
		}
	},
	components: {
		Link,
		ParagraphDescription,
		Paragraphs
	},
	computed: {
		zoomInParameters: (chiasmIdentifier, sectionIdentifier) =>
			({ chiasm: chiasmIdentifier, section: sectionIdentifier }),
		linkParameters: (zoomInParameters, zoomedIn, querystringParameters) => {
			const extraParameters = zoomedIn ? { } : zoomInParameters
			const parametersToCopy = removeProperties(querystringParameters, ['chiasm', 'section'])

			return Object.assign({}, parametersToCopy, extraParameters)
		}
	},
	helpers: {
		getChiasmColor,
		shortSeptetLabel(identifier) {
			const label = septetLabel(identifier)

			return label.length > 1 ? '' : label
		}
	}
}
</script>
