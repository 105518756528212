const code_0 = 48
const code_1 = 49
const code_2 = 50
const code_3 = 51
const code_4 = 52
const code_5 = 53
const code_6 = 54
const code_7 = 55
const code_8 = 56
const code_9 = 57
const code_a = 65
const code_b = 66
const code_c = 67
const code_d = 68
const code_e = 69
const code_f = 70
const code_g = 71
const code_h = 72
const code_i = 73
const code_j = 74
const code_k = 75
const code_l = 76
const code_m = 77
const code_n = 78
const code_o = 79
const code_p = 80
const code_q = 81
const code_r = 82
const code_s = 83
const code_t = 84
const code_u = 85
const code_v = 86
const code_w = 87
const code_x = 88
const code_y = 89
const code_z = 90

export {
	code_0,
	code_1,
	code_2,
	code_3,
	code_4,
	code_5,
	code_6,
	code_7,
	code_8,
	code_9,
	code_a,
	code_b,
	code_c,
	code_d,
	code_e,
	code_f,
	code_g,
	code_h,
	code_i,
	code_j,
	code_k,
	code_l,
	code_m,
	code_n,
	code_o,
	code_p,
	code_q,
	code_r,
	code_s,
	code_t,
	code_u,
	code_v,
	code_w,
	code_x,
	code_y,
	code_z,
}
