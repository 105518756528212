<div class="centered-container">
	<div class="intro">
		<h1>
			Not Found
		</h1>
		{{#if route}}
			<p>No route for {{route}}</p>
		{{/if}}
		<p>
			<StateLink state="main.home">Home</StateLink>
		</p>
	</div>
</div>

<script>
import StateLink from 'component/StateLink.html'

export default {
	components: {
		StateLink
	}
}
</script>
