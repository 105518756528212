<:Window on:resize="debouncedUpdate()" on:scroll="debouncedUpdate()" on:touchmove="debouncedUpdate()" />

<div ref:element>
	{{yield}}
</div>

<script>
import nextTick from 'iso-next-tick'

const average = (a, b) => (a + b) / 2

export default {
	oncreate() {
		try {
			this.updateVisibility()
		} catch (e) {
			console.error('Error in oncreate for some reason', e)
		}

		this.observe('updateOnChange', () => nextTick(() => this.updateVisibility()))
	},
	computed: {
		notVisibleChange: (parent, property, key) => ({
			parent: parent,
			parentProperty: property,
			key: key,
			visible: false,
			top: null,
			bottom: null,
			left: null,
			right: null,
			verticalCenter: null,
			topRatio: null,
			bottomRatio: null,
		})
	},
	methods: {
		setNotVisible() {
			if (this.get('visible')) {
				this.set({ visible: false })
				this.fire('change', this.get('notVisibleChange'))
			}
		},
		debouncedUpdate() {
			this.updateVisibility()

			if (this.timeoutId) {
				clearTimeout(this.timeoutId)
				this.timeoutId = null
			}

			this.timeoutId = setTimeout(() => {
				this.timeoutId = null
				this.updateVisibility()
			}, 100)
		},
		updateVisibility() {
			if (!this.refs.element) {
				this.setNotVisible()
				return
			}

			const relativeToViewport = this.refs.element.firstElementChild.getBoundingClientRect()
			const viewportHeight = window.document.documentElement.clientHeight
			const visible = relativeToViewport.bottom >= 0 && relativeToViewport.top <= viewportHeight

			if (visible) {
				const topRatio = relativeToViewport.top / viewportHeight
				const bottomRatio = relativeToViewport.bottom / viewportHeight

				this.set({ visible })

				this.fire('change', {
					parent: this.get('parent'),
					parentProperty: this.get('parentProperty'),
					key: this.get('key'),
					visible,
					top: relativeToViewport.top,
					bottom: relativeToViewport.bottom,
					left: relativeToViewport.left,
					right: relativeToViewport.right,
					verticalCenter: average(relativeToViewport.top, relativeToViewport.bottom),
					topRatio,
					bottomRatio
				})
			} else {
				// this.log('No longer visible')
				this.setNotVisible()
			}
		},
	}
}
</script>
