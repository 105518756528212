<span class="bar" style="{{style}}">
	<span class="inner-padding">
		<slot>&nbsp;</slot>
	</span>
</span>

<style>
.bar {
	white-space: nowrap;

	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.inner-padding {
	padding-left: 8px;
	padding-right: 8px;
}

</style>

<script>
import { contrastRatio } from 'chromatism2'

const joinTruthy = (...args) => args.filter(value => value).join(' ')

export default {
	data() {
		return {
			yPadding: 4
		}
	},
	computed: {
		foregroundColor(color) {
			return Array.isArray(color)
				? contrastRatio(color[0]).hex
				: contrastRatio(color).hex
		},
		backgroundStyle(color) {
			return Array.isArray(color)
				? `background: linear-gradient(to right, ${color.join(', ')})`
				: `background-color: ${color}`
		},
		widthStyle(width) {
			return width && `width: ${width};`
		},
		colorStyle(backgroundStyle, foregroundColor) {
			return `${backgroundStyle}; color: ${foregroundColor};`
		},
		paddingStyle(yPadding) {
			return `padding-top: ${yPadding}px; padding-bottom: ${yPadding}px;`
		},
		fontStyle(fontSize) {
			return `font-size: ${fontSize === 'small' ? '12px' : '16px'};`
		},
		styleArray(widthStyle, paddingStyle, colorStyle, fontStyle) {
			return [widthStyle, paddingStyle, colorStyle, fontStyle]
		},
		style(styleArray) {
			return joinTruthy(...styleArray)
		}
	},
}
</script>
