<ol class="section-list">
{{#each structure as section}}
	<li>
		<strong class="section-label">
			{{sectionLabel(section)}}
		</strong>
		<ol>
			{{#if !section.introduction && !section.subsections}}
			<li>
				<span class="tiny-color-bar">&nbsp;</span>
				<strong class="subsection-label"></strong>
				{{section.description}} <small><VerseRange range="{{section.range}}" /></small>
			</li>
			{{/if}}

			{{#if section.introduction}}
			<li>
				<span
					class="tiny-color-bar"
					style="background-color: {{getColor('introduction')}}"
				>
				</span>
				<strong class="subsection-label">
					<StateLink
						state="main.structure.text"
						params="{{ { chiasm: section.identifier } }}"
						anchor="{{section.introduction.anchor}}"
					>
						Intro.
					</StateLink>
				</strong>
				{{section.introduction.title}} <small><VerseRange range="{{section.introduction.range}}" /></small>
			</li>
			{{/if}}

			{{#if section.subsections}}
			{{#each section.subsections as subsection}}
			<li>
				<span
					class="tiny-color-bar"
					style="background-color: {{subsection.identifier ? getColor(subsection.identifier) : 'inherit'}}"
				>
				</span>
				<strong class="subsection-label">
					<StateLink
						state="main.structure.text"
						params="{{ { chiasm: section.identifier } }}"
						anchor="{{subsection.anchor}}"
					>
						{{septetLabel(subsection.identifier)}}.
					</StateLink>
				</strong>
				{{subsection.title}} <small><VerseRange range="{{subsection.range}}" /></small>
			</li>
			{{/each}}
			{{/if}}
		</ol>
	</li>
{{/each}}
</ol>

<style>
.subsection-label {
	min-width: 5em;
	display: inline-block;
}
.section-label {
	font-size: larger;
}
</style>

<script>
import getColor from 'lib/structure/identifier-color'
import sectionLabel from 'lib/structure/section-label'
import septetLabel from 'lib/structure/septet-label'

import VerseRange from 'component/VerseRange.html'
import StateLink from 'component/StateLink.html'

export default {
	data() {
		return {
			structure: null
		}
	},
	components: {
		VerseRange,
		StateLink
	},
	helpers: {
		sectionLabel,
		getColor,
		septetLabel
	}
}

</script>
