<ol class="section-list">
{{#each section.subsections as subsection}}
	<li
		style="padding-left: {{indentLevel(subsection.identifier)}}em"
		on:mouseenter="set({ currentlyHovered: subsection.identifier })"
		on:mouseleave="set({ currentlyHovered: null })"
		data-unhighlighted="{{!!currentlyHovered && currentlyHovered !== subsection.identifier}}"
	>
		<span
			class="tiny-color-bar"
			style="background-color: {{getColor(subsection.identifier)}}"
		>
			&nbsp;
		</span>
		<strong>
			<StateLink state="main.structure.text" params="{{ { chiasm: 'E' } }}" anchor="{{subsection.anchor}}">
				{{sectionLabel(subsection)}}:
			</StateLink>
		</strong> {{subsection.title}} <small><VerseRange range="{{subsection.range}}" /></small>
	</li>
{{/each}}
</ol>

<script>
import getColor from 'lib/structure/identifier-color'

import VerseRange from 'component/VerseRange.html'
import StateLink from 'component/StateLink.html'

export default {
	data() {
		return {
			section: null
		}
	},
	components: {
		VerseRange,
		StateLink,
	},
	helpers: {
		sectionLabel(section) {
			return section.prime ? `${section.identifier}′` : section.identifier
		},
		getColor,
		indentLevel(identifier) {
			return {
				Ea: 0,
				Eb: 1,
				Ec: 2,
				Ed: 3,
				Ee: 4,
				Ef: 5
			}[identifier]
		}
	}
}

</script>
