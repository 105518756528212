{{#each subsectionsWithVersesAndSermons as subsection}}
<ColorBarAndContent
	description="{{subsection.title}}"
	sectionIdentifier="{{subsection.identifier}}"
	zoomedIn="{{!!currentSubsection}}"
	anchor="{{useAnchors && subsection.anchor}}"
	siblingAnchor="{{subsection.siblingAnchor}}"
	siblingIsDown="{{subsection.siblingIsDown}}"
	visible="{{subsectionIsVisible(subsection)}}"
	range="{{subsection.range}}"
	sermons="{{subsection.sermons}}"
	verses="{{subsection.verses}}"
	:chiasmIdentifier
	:showVerseNumber
	:querystringParameters
/>
{{/each}}

<script>
import combineStructureWithVerses from 'lib/structure/combine-structure-with-verses'
import combineStructureWithSermons from 'lib/structure/combine-structure-with-sermons'

import pipe from 'lib/pipe'

import ColorBarAndContent from './ColorBarAndContent.html'

export default {
	data() {
		return {
			useAnchors: true
		}
	},
	components: {
		ColorBarAndContent
	},
	computed: {
		subsectionIsVisible: currentSubsection => {
			return subsection => !currentSubsection || subsection.identifier === currentSubsection
		},
		subsectionsWithVersesAndSermons: (subsections, verses, sermons) => {
			return pipe(subsections,
				subsections => combineStructureWithVerses(subsections, verses),
				subsections => combineStructureWithSermons(subsections, sermons))
		}
	}
}
</script>
