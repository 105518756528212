<div class="centered-container">
	<div class="intro">
		<h1>The Revelation Sermons</h1>
		<p>Preached by Phil Kayser at Dominion Covenant Church</p>
	</div>

	<div class="links-and-useful-text">
		<p>
			<a href="/static/podcast.xml">Podcast XML feed</a>
			·
			<ExternalLink
				href="https://itunes.apple.com/us/podcast/revelation-project-sermons/id1281293180"
			>
				On Apple Podcast
			</ExternalLink>
		</p>
		<p>
			Find the podcast in your favorite podcast app by searching for "<strong>Revelation Project: Sermons</strong>".
		</p>
	</div>

	<table class="pure-table pure-table-bordered">
		<thead>
			<tr>
				<th>
					Preached
				</th>
				<th>
					Title
				</th>
				<th>
					Reference
				</th>
				<th>

				</th>
				<th>

				</th>
			</tr>
		</thead>
		<tbody>
			{{#each sermonsDescending as sermon}}
				<tr>
					<td>
						{{displayDate(sermon.date)}}
					</td>
					<td>
						{{sermon.title}}
					</td>
					<td>
						<StateLink
							state="main.structure.text"
							params="{{ { verseNumbers: true } }}"
							anchor="c{{sermon.range[0][0]}}v{{sermon.range[0][1]}}"
						>
							<VerseRange range={{sermon.range}} faint={{false}} />
						</StateLink>
					</td>
					<td>
						<ExternalLink href="https://kaysercommentary.com/{{encodeURI(sermon.filename)}}">
							text
						</ExternalLink>
					</td>
					<td>
						{{#if sermon.audioId}}
							<ExternalLink href="http://www.dominioncovenantchurch.com/sermons/?sermon_id={{sermon.audioId}}">
								audio
							</ExternalLink>
						{{/if}}
					</td>
				</tr>
			{{/each}}
		</tbody>
	</table>
</div>

<style>
table {
	margin-left: auto;
	margin-right: auto;
}

.links-and-useful-text {
	text-align: center;
}
</style>


<script>

import parseDate from 'date-fns/parse'
import StateLink from 'component/StateLink.html'
import ExternalLink from 'component/ExternalLink.html'
import VerseRange from 'component/VerseRange.html'

const months = [
	`January`,
	`February`,
	`March`,
	`April`,
	`May`,
	`June`,
	`July`,
	`August`,
	`September`,
	`October`,
	`November`,
	`December`,
]

export default {
	data() {
		return {
			sermons: [],
		}
	},
	computed: {
		sermonsDescending: sermons => sermons.slice().reverse(),
	},
	helpers: {
		displayDate(dateString) {
			const date = parseDate(dateString)
			return `${ months[date.getMonth()] } ${ date.getDate() }, ${ date.getFullYear() }`
		},
	},
	components: {
		ExternalLink,
		VerseRange,
		StateLink,
	},
}
</script>
