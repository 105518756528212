<div class="menu">
	{{#each menu as item}}
		<span class="menu-box">
			<a class="placeholder bold-placeholder" aria-hidden="true">{{item.name}}</a>
			<StateLink
				state="{{item.state}}"
				href="{{asr.makePath(item.state)}}"
				className="menu-item"
			>
				{{item.name}}
			</StateLink>
		</span>
	{{/each}}
</div>
<hr />

<style>
a {
	white-space: nowrap;
	display: block;
}

hr {
	margin: 0;
	margin-top: 8px;
	border: .5px solid #9a9a9a;
}

.menu {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	background-color: #e0e0e0;
	font-family: 'Lato', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
	font-size: 16px;
}

.menu-box {
	text-align: center;
	margin-bottom: 0px;
	margin-right: 8px;
}
.menu-box:last-of-type {
	margin-right: 0;
}

.menu-item {
	padding: 8px;
	background-color: #e0e0e0;
	color: black;
	border-radius: 2px;
	text-decoration: none;
	font-weight: 400;
}
.placeholder {
	padding: 0 8px;
}
.menu-item[data-active=true] {
	font-weight: 700;
}

.bold-placeholder {
	font-weight: bold;
	visibility: hidden;
	height: 0px;
}
</style>

<script>
import StateLink from 'component/StateLink.html'

export default {
	data() {
		// menu items can have children here too FYI,
		// there just don't happen to be any at the moment
		return {
			menu: [{
				name: `Home`,
				state: `main.home`,
			}, {
				name: `Principles for Interpreting Revelation`,
				state: `main.principles-of-interpretation`,
			}, {
				name: `Sermons`,
				state: `main.sermons`,
			}, {
				name: `The Text`,
				state: `main.structure.text`,
			}, {
				name: `The Book's Structure`,
				state: `main.structure.explanation`,
			}, {
				name: `Historical Timeline`,
				state: `main.timeline.table`,
			}, {
				name: `Different Interpretations of Revelation`,
				state: `main.different-interpretations`,
			}],
		}
	},
	computed: {
		activeParentMenuItem: (menu, asr) => menu.find(item => asr.stateIsActive(item.state)),
	},
	components: {
		StateLink,
	},
}
</script>
