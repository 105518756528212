<div class="top-bar">
	<div class="bb-logo">
		<a href="https://biblicalblueprints.com/" class="link-no-underline">
			<img src="/static/image/bb-logo-dark-optimized.svg" alt="logo" />
		</a>
	</div>
	<div class="bb-menu">
		<ul class="nav-list header-nav-list">
			<li>
				<a href="https://biblicalblueprints.com/start-here">Start Here</a>
			</li>
			<li>
				<a href="https://biblicalblueprints.com/series">Series</a>
			</li>
			<li>
				<a href="https://biblicalblueprints.com/collections">Collections</a>
			</li>
			<li>
				<a href="https://revelation.biblicalblueprints.org/home"><strong>Revelation</strong></a>
			</li>
			<li>
				<a href="https://store.biblicalblueprints.com">Store</a>
			</li>
			<li>
				<a href="https://biblicalblueprints.com/about">About</a>
			</li>
			<li>
				<a href="https://biblicalblueprints.com/give">Give</a>
			</li>
		</ul>
	</div>
	<div class="rev-menu">
		<Menu asr="{{asr}}" />
	</div>
</div>

<uiView></uiView>

<style>
.top-bar {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 1800px;
    margin: 0 auto;
}
.rev-menu {
	margin: 8px;
	padding-top: 8px;
}

/* Styles from KayserCommentary.com */
.bb-logo {
	display: flex;
	justify-content: center;
	margin-left: 8px;
}
.bb-logo img {
	width: 250px;
	height: 80px;
}
.bb-menu {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.nav-list {
	font-family: 'Lato', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
	list-style: none;
	align-items: center;
	margin: 0;
	padding: 10px 20px;
	font-size: 22px;
}
.nav-list li {
	margin: 5px 10px;
	display: inline-block;
	padding: 8px;
	text-align: center;
}
/* .button-border {
	border: 1px black solid;
	border-radius: 2px;
} */
.nav-list a {
	color: #383839;
	text-decoration: none;
	border-bottom: 1px #aaaaaa solid;
	padding-bottom: 1px;
}
.link-no-underline, .link-no-underline:hover {
	border: none;
	padding-bottom: 0;
}

@media (max-width: 500px) {
	.bb-logo {
		margin-left: 0;
		margin-top: 8px;
		width: 100%;
	}
	.bb-menu {
		margin-top: 8px;
		padding-top: 8px;
	}
	.rev-menu {
		margin-left: 0;
	}
	.nav-list {
		padding: 0;
		font-size: 17px;
	}
}
</style>

<script>
import Menu from './Menu.html'

export default {
	components: {
		Menu
	}
}
</script>
