<ol class="section-list">
{{#each structure as section}}
	<li
		style="padding-left: {{indentLevel(section.identifier)}}em"
		on:mouseenter="set({ currentlyHovered: section.identifier })"
		on:mouseleave="set({ currentlyHovered: null })"
		data-unhighlighted="{{!!currentlyHovered && currentlyHovered !== section.identifier}}"
	>
		<span
			class="tiny-color-bar"
			style="background-color: {{getColor(section.identifier)}}"
		>
			&nbsp;
		</span>

		<strong>
			{{#if linkToSameState}}
				<QuerystringLink
					parameters="{{ querystringParameters || {} }}"
					anchor="{{section.anchor}}"
				>
					{{sectionLabel(section)}}:
				</QuerystringLink>
			{{else}}
				<StateLink
					state="main.structure.text"
					anchor="{{section.anchor}}"
				>
					{{sectionLabel(section)}}:
				</StateLink>
			{{/if}}
		</strong>

		{{section.title}}

		<small>
			<VerseRange range="{{getSectionRange(section)}}" />
		</small>
	</li>
{{/each}}
</ol>

<script>
import getColor from 'lib/structure/identifier-color'
import sectionLabel from 'lib/structure/section-label'
import getSectionRange from 'lib/structure/get-section-range'

import VerseRange from 'component/VerseRange.html'
import StateLink from 'component/StateLink.html'

import svelteQuerystringRouter from 'svelte-querystring-router'
const { Link } = svelteQuerystringRouter


export default {
	data() {
		return {
			structure: null,
			linkToSameState: false
		}
	},
	components: {
		VerseRange,
		StateLink,
		QuerystringLink: Link
	},
	helpers: {
		sectionLabel,
		getColor,
		getSectionRange,
		indentLevel(identifier) {
			return {
				A: 0,
				B: 1,
				C: 2,
				D: 3,
				E: 4
			}[identifier]
		}
	}
}

</script>
